import styled from 'styled-components';
import { device } from 'constants/mediaQueries';

const HeroSection = styled.div`
  background: ${({ theme }) => theme.colors.mist};
  padding-top: 216px;
  padding-bottom: 32px;

  @media ${device.mobileL} {
    padding-bottom: 64px;
  }
  
  .row {
    height: initial;

    @media ${device.mobileL} {
      height: 420px;
    }
  }
`;

const WindSection = styled.div`
  padding-top: 74px;
  padding-bottom: 0;

  img {
    margin-bottom: -56px;
  }

  button {
    margin-bottom: 32px;
  }
`;

const OurClientsSection = styled.div`
  background: ${({ theme }) => theme.colors.almond};
  padding-bottom: 64px;
  padding-top: 96px;

  @media ${device.mobileL} {
    padding-bottom: 128px;
  }
  
  h2 {
    padding-bottom: 0;
  }

  p:first-child {
    padding-bottom: 64px;
  }
  
  svg {
    padding-top: 48px;
    
    @media ${device.mobileL} {
      padding-top: initial;
    }
  }
`;

const EarthquakeSection = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.mobileL} {
    padding-top: 64px;
    padding-bottom: 0;
  }

  img {
    margin-bottom: initial;

    @media ${device.mobileL} {
      margin-bottom: -56px;
    }
  }
  
  button {
    margin-bottom: 32px;
  }
`;

const PlatformSection = styled.div`
  background: ${({ theme }) => theme.colors.mist};
  padding-top: 64px;

  @media ${device.mobileL} {
    padding-top: 160px;
  }
  
  h2 {
    font-size: 30px;
    line-height: 40px;

    @media ${device.mobileL} {
      font-size: 48px;
      line-height: 52px;
    }
  }

  p img {
    width: 16px;
  }
  
  p {
    padding-bottom: 64px;
  }
`;

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  width: 100%;
  margin: 8px 0;
`;

export {
  HeroSection,
  WindSection,
  OurClientsSection,
  EarthquakeSection,
  PlatformSection,
  Line,
};
