// @flow
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Aos from 'aos';

import IntroImg from 'images/homepage-tom-barrett-unsplash.jpg';
import ArrowDown from 'images/icons/arrow-down.png';
import ArrowRight from 'images/icons/arrow-right.png';
import Dashboard from 'images/bhi-digital-dashboard.jpg';
import CityImg from 'images/image1.jpg';
import { ReactComponent as CoverholderLogo } from 'images/coverholder-logo.svg';
import { ReactComponent as Map } from 'images/homepage-Map.svg';
import NavBar from 'components/NavBar';
import {
  HeroSection,
  IntroSection,
  IntroPara,
  SpecialitySection,
  UnderwriteSection,
  CityList,
  CoverholderWrapper,
} from './StyledComponents';
import 'aos/dist/aos.css';

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      disable: 'mobile',
      once: true,
    });
  }, []);

  return (
    <>
      <NavBar />
      <HeroSection>
        <Grid>
          <Row bottom="xs">
            <Col xs={12} md={10}>
              <h1 data-aos="fade-right">We design and build specialist insurance products</h1>
              <img data-aos="fade-down" src={ArrowDown} alt="arrow" />
            </Col>
          </Row>
        </Grid>
      </HeroSection>
      <IntroSection>
        <Grid>
          <Row middle="xs">
            <Col xs={12} className="hidden-md hidden-lg hidden-xl">
              <img src={IntroImg} alt="Intro" />
            </Col>
            <Col xs={12} md={6}>
              <div style={{ paddingRight: 24, paddingTop: 64 }}>
                <IntroPara>
                  BHI Digital is headquartered in Miami, Florida.
                  On an E&S basis, it is licensed to underwrite
                  US-domiciled risks in all 50 States.
                </IntroPara>
                <p>
                  Founded in March 2020, BHI Digital is a specialist underwriting agency for small to mid-market
                  businesses in the USA that seeks value where others do not. We design and build specialist insurance
                  products, which are underwritten via a proprietary technology & analytics-led Coverholder platform.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6} className="hidden-xs hidden-sm">
              <img src={IntroImg} alt="Intro" />
            </Col>
          </Row>
        </Grid>
      </IntroSection>
      <SpecialitySection>
        <Grid>
          <Row center="xs">
            <Col xs={12} md={10}>
              <p className="size-18" style={{ paddingBottom: 16 }}>Our Products</p>
              <h2 data-aos="fade-right">Specialty Insurance Made Simple</h2>
              <p data-aos="fade-left">
                BHI Digital is committed to service outperformance for ‘small, simple risks’. Our current product
                offering encompasses Wind and Earthquake Deductible Buybacks. We combine speed with personalized service
                and offer multichannel distribution via portal access, API and traditional submit to quote.
              </p>
              <Link to="/our-products">
                <p className="size-18"><img src={ArrowRight} alt="arrow" /> Read More</p>
              </Link>
              <img data-aos="fade-up" src={Dashboard} alt="Dashboard" />
            </Col>
          </Row>
        </Grid>
      </SpecialitySection>
      <UnderwriteSection>
        <Grid>
          <Row>
            <Col xs={12}>
              <p className="size-18">Where we trade</p>
              <h2 data-aos="fade-right">We underwrite in all 50 states</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <CityList>
                <p>In cities such as:</p>
                <p>Houston, TX</p>
                <p>Miami, FL</p>
                <p>Denver, CO</p>
                <p>San Francisco, CA</p>
                <p>Kansas City, MO</p>
                <p>Oklahoma City, OK</p>
                <p>+ policies in 32 States</p>
              </CityList>
            </Col>
            <Col xs={12} md={9}>
              <Map />
            </Col>
          </Row>
          <Row middle="xs">
            <Col xs={12} md={5}>
              <img src={CityImg} alt="City" />
            </Col>
            <Col xs={12} md={1} />
            <Col xs={12} md={6}>
              <div style={{ paddingRight: 24 }}>
                <p className="size-18">Who we are</p>
                <h2 data-aos="fade-left">
                  BHI Digital is a specialist small to mid-market Coverholder
                </h2>
                <p>
                  We design products for insureds that are unable to source suitable coverage for all of their
                  exposures in the traditional marketplace, due to diluted distribution networks for difference in
                  conditions products.
                </p>
                <Link to="/about-us">
                  <p className="size-18"><img src={ArrowRight} alt="arrow" /> Read More</p>
                </Link>
              </div>
            </Col>
          </Row>
          <Row end="xs">
            <CoverholderWrapper>
              <CoverholderLogo />
            </CoverholderWrapper>
          </Row>
        </Grid>
      </UnderwriteSection>
    </>
  );
};

export default Home;
